.slideover {
  background-color: white;
  border-radius: 33px;
  position: fixed;
  left: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optionsContainer {
  border-top: 1px lightgrey solid;
  width: 20rem;
  height: 15rem;
  margin-top: -3rem;
}

.optionItems {
  border-radius: 15px;
  background-color: white;
  /* opacity: 0.3; */
  width: auto;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.optionText {
  font-size: x-large;
  color: black;
}

.optionsButtonsClicked {
  border-radius: 15px;
  background-color: white;
  opacity: 0.3;
  width: auto;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
