.options {
  border-bottom: 1px solid lightgrey;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.optionsButtonsClicked:active {
  background-color: lightgray;
}
