
/* h2 {
  text-align: center;
} */

.rating.rating-md {
  align-items: center;
  width: 368px; /* Default width for larger screens */
  padding-bottom: 1rem;
  text-align: center;
}
.center-content {
  align-items: center;
}

/* p {
  margin-top: 1rem;
} */

.btn.btn-wide {
  margin-top: 1rem;
}

.container {
  /* Set the default width to 360px */
  width: 360px;
  margin: auto;
  text-align: center;
  transition: width 0.3s ease-in-out; /* Smooth transition for width change */
}
.textbox {
  width: 100%; /* Makes each textbox take the full width of its parent container */
  padding: 10px; /* Adds some padding inside the textboxes for better text visibility */
  margin-bottom: 1rem; /* Adds some space between the textboxes */
  box-sizing: border-box; /* Ensures padding does not add to the overall width */
}
.textarea{
  width: 8%
}

