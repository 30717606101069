.content {
  padding-top: 16rem;
  position: relative;
  bottom: 0.5rem;
  z-index: 1;
}

.backButton {
  background-color: black;
  opacity: 0.33;
  /* position: sticky;
  top: 0.5rem;
  z-index: 3; */
  margin-top: 4rem;
  margin-left: 1rem;
}

.itemImg {
  position: fixed;
  aspect-ratio: 1/1;
}

.itemWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* position: absolute;
  bottom: 6rem; */
}

.itemName_container {
  background-color: #000;
  color: #fff;
  opacity: 0.5;
  height: 4rem;
  width: 20rem;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.itemName {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 1rem;
}

.itemCard {
  border-radius: 33px;
  background-color: white;
  height: 40rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.itemDetails {
  height: auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem;
}

.itemDescription {
  width: 100%;
  /* margin: 1rem; */
}

.itemh2 {
  color: green;
  font-size: 25px;
  font-weight: bold;
  margin: 5px;
}

.divider {
  color: lightgrey;
}

.itemh3 {
  color: #000;
  font-size: 13px;
  margin: 1rem;
}

.sizeButtonWrap {
  margin: 1rem;
  display: flex;
  justify-content: space-evenly;
}

.buttonText {
  color: #fff;
}

.selectedSize {
  border: 2px solid red;
}

.addItemWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: auto;
  position: fixed;
  bottom: 0;

}

.quantityWrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.shoppingCartButton {
  display: flex;
  justify-content: center;
}

.addItemContent {
  display: flex;
  justify-content: space-around;
  
}

.vl {
  border-left: 1px solid lightgray;
  height: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.optionsWrap {
  width: 90%;

}
/* 
.optionsTitle {
  background-color: lightgrey;
} */

.count {
  display: flex;
  justify-content: center;
  width: 10rem;
  font-size: 20px;
}
