


/* .rating.rating-xs {
  text-align: center;
  padding-bottom: 1rem;
} */
.rating.rating-md {
  
padding-bottom: 1rem;}
.center-content {


  align-items: center;
}

.btn.btn-wide{
  margin-top: 2rem;
}
.container{
  width: 360px;
  margin: auto;
}
.fixed-button {
  position: relative;
  bottom: 20px; /* Adjust the bottom offset as needed */
  left: 50%; /* Position the button horizontally in the center */
  transform: translateX(-50%); /* Center the button horizontally */
  z-index: 1000; /* Ensure the button is above other elements */
}